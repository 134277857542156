<template>
	<div class="overlay-layer app-layer is-active">
		<VOverlay
			:title="$t('pages.home.pageTitle')"
			:isLarge="true"
			:isVCentered="false"
			:closeOnBackgroundClick="false"
			:hasBackground="false"
			:hasCloseButton="false"
			:modelValue="true"
			:useFocusTrap="false"
			:closeWithEscape="false"
		>
			{{ $t('pages.home.pageText') }}
			<div class="home-cards-container">
				<div
					class="home-card"
					v-bind:key="index"
					v-for="(item, index) in homeItems"
				>
					<a
						v-if="item.isExternal"
						class="home-card-link"
						:href="item.href"
						@click.prevent.stop="
							() => externalLinkClicked(item.href)
						"
					>
						<div class="home-card-content">
							<VIcon :name="item.meta.icon" />
						</div>
					</a>
					<RouterLink
						v-else
						:to="item.path"
						class="home-card-link"
						active-class="is-active"
					>
						<div class="home-card-content">
							<VIcon :name="item.meta.icon" />
						</div>
					</RouterLink>
					<p class="home-card-text">{{item.meta.title}}</p>
				</div>
			</div>
		</VOverlay>
	</div>
</template>

<script>
import { reactive, toRefs } from '@vue/reactivity';
import { unref, watch } from 'vue';
import ability, { userHasAccess } from '@assets/scripts/auth';
import { routes } from '@assets/scripts/composables/useRoutes';

export default {
	name: 'TheHomepage',
	setup: function () {
		const state = reactive({
			homeItems: [],
		});

		const setHomeItems = () => {
			state.homeItems = unref(routes).filter((route) => {
				// only show menu items for routes that do not need
				// permissions, or if current user has correct permissions
				return userHasAccess(route) && route.meta.showInHome;
			});
		};

		// watch for changes in available routes
		watch(routes, () => {
			setHomeItems();
		});

		// subscribe to updates to abilities
		// https://casl.js.org/v6/en/guide/intro#update-rules
		ability.on('updated', () => {
			// set new access per route
			setHomeItems();
		});

		// initially set acces per route
		setHomeItems();

		return {
			...toRefs(state),
		};
	},
	methods: {
		externalLinkClicked: function (href) {
			window.location = href;
		},
	},
};
</script>
